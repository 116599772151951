/* eslint-disable jsx-a11y/role-supports-aria-props */
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Filter from "../../images/filters-primary.svg";
import ViewPrimary from "../../images/view-primary.svg";
import Search from "../../images/search-small.svg";
import DownArrow from "../../images/down-arrow.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import client from "../../utils/ApiClient";
import { BenefitsProURL, GetCarrierSubdomain, GetEmulatorStatus, GetEmulatorStatusDataWithPlanID, GetInsertRedirectEnvlope, GetMemberInfoWithCarrierID, GetMemberInformation, colourMapping, mappingData, resolution, year, GetBenefitsForCarrier, GetImageCDNUrl, statusList, ActiveInactiveList, getEnvironment, getEnvironmentUrl, getMEPUIEnvironmentUrl, undefinedConst, OverrideIcon, GetExcelByteArray, getSelectedRole, prod, prodcentus, prodceus2, SelectedYear, BenefitYears, BenefitYear, GetMultiTenantToken, MyBenefitsURL, formatDate, EH_MyBenefitsURL } from '../../Constants/AppConstants';
import { IPopUp, InsuranceHealthPlans, MemberInform, MemberInformation, PlanData, TokenInformation, BenefitIcons, IExistingMembers } from '../../Interfaces/InsuranceHealthPlans';
import ExternalWhite from "../../images/external-white.svg";
import ExternalGrey from "../../images/external-blue.svg";
import DownArrowBlack from "../../images/down-arrow-black.svg";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import calendarIcon from "../../images/calendar-icon.svg";
import InReview from "../../images/inreview-icon.svg";
import Info from "../../images/info.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import moment from "moment";
import DropdownButton from 'react-bootstrap/DropdownButton';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from 'react-window';
import Download from "../../images/download.svg";
import React from 'react';
import { GetKeyValueFromLocalStorage, RemoveKeyFromLocalStorage, SetKeyToLocalStorage } from '../../Constants/LocalStorage';
import { useIsAuthenticated } from '@azure/msal-react';
import { NotFound } from './NotFound';



// Plans component.
export const Plans = (props: any) => {

    // Use history.
    const navigate = useNavigate();

    // Instance.
    const { instance } = useMsal();

    // Accounts.
    const accounts = instance.getAllAccounts();

    // Popup variable.
    const popup: IPopUp = {
        showPopUp: false,
        isEmulator: false,
        isACCConfigurationCompleted: false,
        accConfigurationComments: '',
        isBenefitConfigurationCompleted: false,
        benefitConfigurationComments: '',
        isFundsLoadingCompleted: false,
        fundsLoadingComments: '',
        isCardCreated: false,
        isCardCreatedComments: '',
        isMemberCreated: false,
        memberCreationComments: '',
        isPublishingCompleted: false,
        isPublishingComments: '',
        isSiteConfigurationCompleted: false,
        siteConfigurationComments: '',
        isPublishFirstTime: false,
        insuranceCarrierId: '',
        healthPlanId: ''
    }

    //MemberInformation
    let memberDetails: MemberInformation = {
        healthPlanId: "",
        carrierId: "",
        nhMemberId: "",
        memberInsuranceID: "",
        memberId: "",
        userName: "",
        effectiveDate: "",
        firstname: "",
        lastName: "",
        ssn: "",
        dateOfBirth: "",
        isEmulatorMode: false,
        insuranceNbr: "",
        success: true
    }

    // State variable for showing or hiding the search box
    const [showSearchBox, setShowSearchBox] = useState(false);

    // State variable for is mobile mode.
    const [isMobileMode, setIsMobileMode] = useState<boolean>(false);

    // State variable for screen width.
    const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Initialize with current screen width

    // State variable for show date picker.
    const [showDatePicker, setShowDatePicker] = useState(false);

    // State variable for username.
    const [userName, setUserName] = useState<string>('');

    // State variable for total plans count.
    const [totalPlansCount, setTotalPlansCount] = useState<number>(0);

    // Login user name.
    const loggedInUserName = accounts[0]?.username.split("@")[0]?.toLowerCase();

    // State variable for sub domain.
    const [subDomain, setSubDomain] = useState<string | null>(null);

    // State variable for date object.
    const dateObject = new Date(year, 0, 1);

    // State variable for list of information.
    const [listOfInformation, setListOfInformation] = useState<PlanData[]>();

    // State variable for unique benefit types array.
    const [uniqueBenefitTypesArray, setUniqueBenefitTypesArray] = useState<string[]>([]);

    // State variable to show activity popup.
    const [showActivity, setShowActivity] = useState<IPopUp>(popup);

    // State variable for insurance health plans.
    const [insuranceHealthPlans, setInsuranceHealthPlans] = useState<InsuranceHealthPlans[]>();

    // State variable for selected healthPlan name.
    const [selectedHealthPlan, setSelectedHealthPlan] = useState<string>('');

    // State variable for Benefit Icons.
    const [benefitImageIcons, setBenefitImageIcons] = useState<BenefitIcons[]>();

    // State variable for Search.
    const [search, setSearch] = useState('');

    // State variable for searchresults.
    const [isNoSearchResult, setIsNoSearchResult] = useState(false);

    // State variable for Loader.
    const [loader, setLoader] = useState(true);

    // State variable for filter.
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

    // State variable for selected check box status.
    const [selectedCheckBoxStatus, setSelectedCheckBoxStatus] = useState<string[]>([]);

    // State variable for selected check box status.
    const [allselectedCheckBoxStatus, setAllselectedCheckBoxStatus] = useState<string[]>([]);

    // State variable for selected status.
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

    // State variable for filtered status.
    const [filteredStatus, setFilteredStatus] = useState<string[]>([]);

    // State variable for toggle filter.
    const [toggleFitlerVisibility, setToggleFitlerVisibility] = useState(false);

    /// State variable for redirect token.
    const [redirectToken, setRedirectToken] = useState('');


    /// State variable for multitenant token.
    const [multiTenantToken, setMultiTenantToken] = useState('');

    // State variable for preview date.
    const [previewDate, setPreviewDate] = useState<string>(moment(new Date()).format("MM-DD-YYYY"));

    // State variable for list of members.
    const [listOfMembers, setListOfMembers] = useState<MemberInform[]>();

    // State variable for selected option.
    const [selectedOption, setSelectedOption] = useState<string>('');

    // State variable for member info.
    const [memberInfo, setMemberInfo] = useState<MemberInformation>(memberDetails);

    // Variable for selected filter status.
    const selectedfilterStatusText = selectedCheckBoxStatus.join(', ');

    // Variable for status options.
    const statusOptions = ['New', 'Draft', 'Pending Approval', 'Pending Publish', 'Published'];

    // State variable for loader.
    const [isLoading, setIsLoading] = useState(false);

    //List of existing members
    const [listOfExistingMembers, setListOfExistingMembers] = useState<IExistingMembers[]>();

    //State varible for ExistingMembers
    const [existingMembers, setExistingMembers] = useState(false);

    //State varible for searchedMemberInfo
    const [searchedMemberInfo, setSearchedMemberInfo] = useState<string>('');

    //State varible for isValidMember
    const [isValidMember, setIsValidMember] = useState(true);

    //State varible for isMemberEntered
    const [isMemberEntered, setIsMemberEntered] = useState(true);

    // State variable to show activity popup.
    const [showActivityPopup, setShowActivityPopup] = useState(false);

    // State varible for loader.
    const [loading, setLoading] = useState(false);

    // State variable for current template identifier.
    const [currentTemplateId, setCurrentTemplateId] = useState<number>(0);
    const carrierEffectiveFrom = props.activeInsuranceCarrier.effectiveFromDate;
    const carrierEffectiveTo = props.activeInsuranceCarrier.effectiveToDate;

    // Handles the actvity popup close button/icon.
    const handleActivityCloseExcel = () => {
        setShowActivityPopup(false);
    }

    // State variable for benefityears.
    const [benefitYears, setBenefitYears] = useState([]);

    // State variable to set the selected benefit year and status.
    const [selectedYear, setSelectedYear] = useState<{ benefitYear: number; status: string; }>({
        benefitYear: 0,
        status: ""
    });

    //search Member Info
    const getSearchMemberInfo = (e: any) => {
        setSearchedMemberInfo(e.target.value);
    }

    // Function to handle navigation to the plan details page
    const navigateToPlanDetailsPage = (planDetails: InsuranceHealthPlans) => {
        navigate("/PlanDetails", { state: { insuranceCarrier: props.activeInsuranceCarrier, planDetails: planDetails } });
    };

    // Handles the click even of health plan.
    const handleHealthPlanClick = (insuranceHealthPlan: InsuranceHealthPlans) => {
        if (!isMobileMode) {
            navigateToPlanDetailsPage(insuranceHealthPlan)
        }
    }

    // Function to toggle the visibility of the search box
    const toggleSearchBox = () => {
        setShowSearchBox(!showSearchBox);
    };

    // Function to update the screen width state on window resize
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    // State variable for Active Carriers
    const [isActive, setIsActive] = useState(true);

    // State variable for InActive Carriers
    const [isInActive, setIsInActive] = useState(false);

    // State variable for All Carriers
    const [isAll, setIsAll] = useState(false);

    // State variable for insurance carriers.
    const [totalHealthplans, setTotalHealthplans] = useState<InsuranceHealthPlans[]>();

    // Handles the actvity popup close button/icon.
    const handleActivityClose = () => {
        setShowActivity(popup);
        setRedirectToken('');
        setSelectedOption('');
        setPreviewDate(moment(dateObject).format("MM-DD-YYYY"));
        setUniqueBenefitTypesArray([]);
        setListOfInformation([]);
        setListOfExistingMembers([]);
        setExistingMembers(false);
        setSearchedMemberInfo('');
        setMemberInfo(memberDetails);
        setIsMemberEntered(true);
    }

    const handlePreviewDate = () => {
        let emulatorDate = props.activeInsuranceCarrier.benefitYear === year + 1 ? moment(new Date(year + 1, 0, 1)).format("MM-DD-YYYY") : moment(new Date()).format("MM-DD-YYYY");
        setPreviewDate(emulatorDate);
    }

    //Handle key down
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {

            if (!!searchedMemberInfo) {
                onClickHandler();
            }
            else {
                setIsMemberEntered(false);
            }
        }
    };

    // Is authenticated variable.
    const isAuthenticated = useIsAuthenticated();

    // Downloads the excel file based on the template id.
    const downloadExcelFile = (templateId: number) => {
        if (isAuthenticated) {
            setShowActivityPopup(true);
            setCurrentTemplateId(templateId);
        }
    }

    // Saves the excel file.
    const saveExcelFile = () => {
        let environment: any = (process.env.REACT_APP_CUSTOM_NODE_ENV?.toLowerCase() === prodceus2 || process.env.REACT_APP_CUSTOM_NODE_ENV?.toLowerCase() === prodcentus || process.env.REACT_APP_CUSTOM_NODE_ENV?.toLowerCase() === prod) ? "" : " " + process.env.REACT_APP_CUSTOM_NODE_ENV?.toUpperCase();
        setLoading(true);
        const apiUrl = `${GetExcelByteArray}${currentTemplateId}&insuranceCarrierId=${props.activeInsuranceCarrier.insuranceCarrierId}&insuranceCarrierName=${props.activeInsuranceCarrier?.insuranceCarrierName}&benefitYear=${props.activeInsuranceCarrier?.benefitYear}&Role=${getSelectedRole()}&User=${GetKeyValueFromLocalStorage('username')}`;
        client.get(apiUrl)
            .then((response) => {
                const linkSource = `data:application/vnd.ms-excel.sheet.macroEnabled.12;base64,${response.data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = `${props.activeInsuranceCarrier?.benefitYear}_${props.activeInsuranceCarrier.insuranceCarrierId}_${props.activeInsuranceCarrier?.insuranceCarrierName.replaceAll('.', ' ')}_Benefits Configuration_${getSelectedRole()}${environment}`;
                downloadLink.target = "_blank";
                downloadLink.click();
                setShowActivityPopup(false);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }


    // To get the multitenant token.
    const getTenantToken = async () => {
        try {
            const tenantBody = {
                tenantCode: getEnvironment(props.activeInsuranceCarrier.environmentId).toString()
            };
            const response = await client.post(GetMultiTenantToken, tenantBody);
            const token = response?.data?.accessToken;
            setMultiTenantToken(token);
            return token;
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            return null;
        }
    };



    const onClickHandler =async () => {

        setIsLoading(true);
        let isNH: boolean = startsWithNhOrEh(searchedMemberInfo);
        let memberData = {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            insuranceCarrierId: Number(props.activeInsuranceCarrier.insuranceCarrierId),
            nhMemberId: isNH ? searchedMemberInfo : '',
            phoneNumber: 0,
            address: "",
            state: "",
            zipCode: "",
            poNumber: 0,
            orderId: 0,
            city: "",
            email: "",
            otcCardNumber: "",
            insuranceMemberId: isNH ? "" : searchedMemberInfo,
            searchType: ""
        }
        const tenantToken = await getTenantToken();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'user-authorization': tenantToken,
            }
        }
        client.post(getEnvironmentUrl(Number(props.activeInsuranceCarrier.environmentId)), memberData, config)
            .then((response) => {
                let data: IExistingMembers[] = response?.data?.result;
                if (data.length > 0) {
                    setIsValidMember(true);
                    setIsMemberEntered(true);
                    handleRadioChange(data[0].nhMemberId);

                } else {
                    setSelectedOption('')
                    setRedirectToken('');
                    setMemberInfo(memberDetails);
                    setIsValidMember(false);
                }

                setListOfExistingMembers(response?.data?.result);
                setIsLoading(false);

            })
            .catch((error) => {
                setIsLoading(false);
                setIsValidMember(false);
            }).finally(() => {
                const sectionElement = document.getElementById(`previewDateId`);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: "smooth" });
                }
            })
    }

    const startsWithNhOrEh = (text: string) => {
        // Convert the input text to lowercase for case-insensitive comparison
        const lowercaseText = text.toLowerCase();

        // Check if the text starts with "nh" or "eh"
        if (lowercaseText.startsWith("nh") || lowercaseText.startsWith("eh")) {
            return true;
        } else {
            return false;
        }
    }

    // Onchange event for searchbox.
    const getSearchtext = (e: any) => {
        setSearch(e.target.value);
        //setInsuranceHealthPlans(totalHealthplans);
        if ((e.target.value).length > 2) {
            //ActiveOrInActivePlans(ActiveInactiveList.all);
            totalHealthplans?.filter(x => x?.healthPlanName?.toLowerCase()?.includes(e.target.value?.trim()?.toLowerCase()))
                .filter(x => {
                    if (selectedStatus.length === 0) {
                        return true;
                    }
                    return (selectedStatus?.includes(x.benefitStatus) || selectedStatus?.includes(x.siteStatus));
                }).length === 0 ? setIsNoSearchResult(true) : setIsNoSearchResult(false);
            const healthPlansCount: number = insuranceHealthPlans?.filter(x => x?.healthPlanName?.toLowerCase()?.includes(e.target.value?.toLowerCase()))
                .filter(x => {
                    if (selectedStatus.length === 0) {
                        return true;
                    }
                    return (selectedStatus?.includes(x.benefitStatus) || selectedStatus?.includes(x.siteStatus));
                }).length || 0;
            setTotalPlansCount(healthPlansCount);
        }
        else {
            setIsNoSearchResult(false);
            const healthPlansCount: number = (totalHealthplans && totalHealthplans?.filter(x => {
                if (selectedStatus.length === 0) {
                    return true;
                }
                return (selectedStatus?.includes(x.benefitStatus) || selectedStatus?.includes(x.siteStatus));
            }).length) || 0;
            setTotalPlansCount(healthPlansCount);
        }
    }

    // To return filtered healthplan count.
    const getHealthPlanCount = () => {
        return insuranceHealthPlans && insuranceHealthPlans?.filter(x => search?.length > 2 ? x?.healthPlanName?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) : true)
            .filter(x => {
                if (selectedStatus.length === 0) {
                    return true;
                }
                return (selectedStatus?.includes(x.benefitStatus) || selectedStatus?.includes(x.siteStatus));
            }).length;
    }

    // Get Token
    const showPopUp = (insuranceHealthPlan: InsuranceHealthPlans, isEmulator: number) => {
        handlePreviewDate();
        setSelectedHealthPlan(insuranceHealthPlan.healthPlanName);
        if (isEmulator === 1) {
            MemberInform(insuranceHealthPlan.insuranceCarrierId, insuranceHealthPlan.insuranceHealthPlanID);
        }
        else if (isEmulator === 0) {
            getPlanInformation(insuranceHealthPlan.insuranceHealthPlanID, props.activeInsuranceCarrier.benefitYear, props.activeInsuranceCarrier.environmentId)

        }
    }

    const handleRadioChange = (event: string) => {
        setSelectedOption(event);
    };

    //Get Token Information
    const getTokenInformation = () => {
        const obj: TokenInformation = {
            NHMemberId: memberInfo.nhMemberId,
            CarrierId: memberInfo.carrierId,
            HealthPlanId: memberInfo.healthPlanId,
            memberInsuranceID: memberInfo.memberInsuranceID,
            MemberId: memberInfo.memberId,
            UserName: memberInfo.userName,
            EffectiveDate: moment(previewDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
            Firstname: memberInfo.firstname,
            Lastname: memberInfo.lastName,
            SSN: memberInfo.ssn,
            DateOfBirth: memberInfo.dateOfBirth,
            IsEmulatorMode: true,
            insuranceNbr: memberInfo.insuranceNbr,
            loggedInUserName: loggedInUserName
        }
        const addBenefitRequest = {
            envelop: JSON.stringify(obj)
        }

        client.post(GetInsertRedirectEnvlope, addBenefitRequest)
            .then((response) => {

                setRedirectToken(response?.data?.redirectionToken);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            })

    }

    const getMemberInformation = (nHMemberId: string) => {
        setIsLoading(true);
        client.get(`${GetMemberInformation}/${nHMemberId}/${props.activeInsuranceCarrier.environmentId}`)
            .then((response) => {
                let index = 0;
                index = response?.data?.data?.memberInsurances.findIndex((e: any) => {
                    return (e?.insuranceCarrierID === props.activeInsuranceCarrier.insuranceCarrierId && e?.insuranceHealthPlanID === showActivity.healthPlanId);
                });
                if (index === -1) {
                    index = 0;
                }
                if (response?.status === 200) {
                    setMemberInfo((prev: MemberInformation) => ({
                        healthPlanId: showActivity.healthPlanId,
                        carrierId: props.activeInsuranceCarrier.insuranceCarrierId,
                        nhMemberId: nHMemberId,
                        memberInsuranceID: response?.data?.data?.memberInsurances[index]?.insuranceDetail?.memberInsuranceID,
                        memberId: response?.data?.data?.memberID,
                        userName: userName,
                        effectiveDate: previewDate,
                        firstname: response?.data?.data?.firstName,
                        lastName: response?.data?.data?.lastName,
                        ssn: response?.data?.data?.ssnLast4,
                        dateOfBirth: moment(response?.data?.data?.dateOfBirth).format('YYYY-MM-DD'),
                        isEmulatorMode: true,
                        insuranceNbr: response?.data?.data?.memberInsurances[index]?.insuranceDetail?.insuranceNbr,
                        success: true
                    }));

                }
                else {
                    setMemberInfo((prev: MemberInformation) => ({
                        ...prev,
                        success: false
                    }));
                    setRedirectToken('');
                    setIsLoading(false);
                }

            })
            .catch((error) => {
                setIsLoading(false);
                setMemberInfo((prev: MemberInformation) => ({
                    ...prev,
                    success: false
                }));
                setRedirectToken('');
            }).finally(() => {
                const sectionElement = document.getElementById(`previewDateId`);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: "smooth" });
                }
            })

    }

    const fetchInsuranceHealthPlansOnCarrierId = async () => {
        try {
            let selectedBenefitYear = GetKeyValueFromLocalStorage(SelectedYear) && JSON.parse(GetKeyValueFromLocalStorage(SelectedYear));
            let benefitYear = selectedBenefitYear?.benefitYear ?? props.activeInsuranceCarrier.benefitYear;
            let effFromDate = formatDate(benefitYear === year + 1 ? new Date(benefitYear, 0, 1) : new Date());
            let effToDate = formatDate(new Date(benefitYear, 11, 31));
           
            const apiUrl = `${GetEmulatorStatus}${props.activeInsuranceCarrier.insuranceCarrierId}/${benefitYear}?EnvironmentId=${props.activeInsuranceCarrier.environmentId}`;
            const response = await client.get(apiUrl);
            //setInsuranceHealthPlans(response.data.filter((x: InsuranceHealthPlans) => x.isActivePlan === true));
            setInsuranceHealthPlans(response.data.filter((x: InsuranceHealthPlans) => x.isActivePlan === true
                && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === benefitYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                && ((formatDate(new Date(carrierEffectiveFrom)) <= effFromDate || new Date(carrierEffectiveFrom).getFullYear() === benefitYear) && formatDate(new Date(carrierEffectiveTo)) >= effToDate)
            ));
            setTotalHealthplans(response.data);
            setLoader(false);
        }
        catch (error) {
            setLoader(false);
        }
    }

    // Handles the benefit year selection.
    const handleBenefitYearChange = (selectedData: any) => {
        setSelectedYear(selectedData);
        props.updateBenefitYear(selectedData);
        SetKeyToLocalStorage(SelectedYear, JSON.stringify(selectedData));
        ActiveOrInActivePlans(ActiveInactiveList.active);
        RemoveKeyFromLocalStorage(BenefitYear);
    };

    const MemberInform = (carrierId: number, planId: number) => {
        //Get Member Info
        client.get(`${GetMemberInfoWithCarrierID}/${carrierId}/${planId}`)
            .then((response) => {
                let selectedBenefitYear = GetKeyValueFromLocalStorage(SelectedYear) && JSON.parse(GetKeyValueFromLocalStorage(SelectedYear));
                let benefitYear = selectedBenefitYear?.benefitYear ?? props.activeInsuranceCarrier.benefitYear;
                let selectedMember = response.data?.find((x: any) => x.benefitYear === benefitYear);
                selectedMember?.nhMemberID &&  setSelectedOption(selectedMember?.nhMemberID);
                setListOfMembers(response.data);
            }).catch((error) => {
            }).finally(() => {
                setShowActivity((e: IPopUp) => {
                    return {
                        ...e, showPopUp: true,
                        isEmulator: true,
                        healthPlanId: planId?.toString(),
                        insuranceCarrierId: carrierId?.toString()
                    }
                })
            })
        getSubdomain(carrierId, planId);

    }

    // Gets the subdomain based on carrierid,benefitYear,healthPlanId
    const getSubdomain = (insuranceCarrierId: number, insuranceHealthPlanId: number) => {
        //Get Subdomain
        client.get(`${GetCarrierSubdomain}/${insuranceCarrierId}/${insuranceHealthPlanId}`)
            .then((response) => {
                setSubDomain(response?.data[0]);

            }).catch((error) => {
            }).finally(() => {
            })
    }

    const getPlanInformation = (planId: number, benefitYear: number, environmentId: number) => {
        client.get(`${GetEmulatorStatusDataWithPlanID}/${planId}/${benefitYear}?EnvironmentID=${environmentId}`)
            .then((response) => {
                setListOfInformation(response.data);
                // Create a Set to store unique benefit types
                const uniqueBenefitTypes = new Set<string>();

                // Iterate through the array and add unique benefit types to the Set
                response.data && response.data.forEach((item: { benefitType: string; }) => {
                    uniqueBenefitTypes.add(item.benefitType);
                });

                // Convert the Set to an array
                const updatedArray = Array.from(uniqueBenefitTypes);

                // Update the state with the updatedArray
                setUniqueBenefitTypesArray(updatedArray);


            }).catch((error) => {
            }).finally(() => {
                setShowActivity((e: IPopUp) => {
                    return {
                        ...e, showPopUp: true,
                        isEmulator: false
                    }
                })
            })
    }

    const benefitIcons = async () => {
        try {
            let selectedBenefitYear = JSON.parse(GetKeyValueFromLocalStorage(SelectedYear));
            let benefitYear = selectedBenefitYear?.benefitYear ?? props.activeInsuranceCarrier.benefitYear;
            const apiUrl = `${GetBenefitsForCarrier}/${props.activeInsuranceCarrier.insuranceCarrierId}/${benefitYear}`;
            const response = await client.get(apiUrl);
            setBenefitImageIcons(response.data);
            setLoader(false);
        }
        catch (error) {
            console.log(error);
            setLoader(false);
        }
        filterFlexBenefitIcons();
    }

    const filterFlexBenefitIcons = async () => {
        benefitImageIcons && benefitImageIcons?.filter(x => x.benefitType === "flex")
    }

    let isAction = !!insuranceHealthPlans ? (insuranceHealthPlans.filter((e: InsuranceHealthPlans) => {
        return (e.showicon === 0 || e.showicon === 1)
    }).length) > 0 : false;

    // Benfits pro url
    const MyBenefitsProURL =
        props.activeInsuranceCarrier.environmentId === 2
            ? EH_MyBenefitsURL
            : MyBenefitsURL;

    // Memoized rows on filter operation applied.
    const FilteredMemoizedRow = React.memo(({ key, style, insuranceHealthPlan, benefitImageIcons, handleHealthPlanClick, isAction, colourMapping }: any) => {
        let isEmulator = insuranceHealthPlan.showicon;
        let siteStatus = colourMapping.filter((e: any) => (e?.value?.toLowerCase() === insuranceHealthPlan.siteStatus?.toLowerCase()))[0];
        let benefitStatus = colourMapping.filter((e: any) => (e?.value?.toLowerCase() === insuranceHealthPlan.benefitStatus?.toLowerCase()))[0];
        return (<div style={{ ...style }} key={key}>
            <Table className='h-100 w-100' style={{ border: "1px solid #F5F5F5", borderTop: 'none' }}>
                <tbody>
                    <tr>
                        <td className="plans-info" style={{ width: "532px" }} onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>{insuranceHealthPlan.healthPlanName}</td>
                        <td className="benefits-count" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span>{(benefitImageIcons && benefitImageIcons?.filter((x: any) =>
                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan.healthPlanName?.toLowerCase())).length)}</span></td>
                        <td className="benefit-type" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>
                            <div className="benefits">
                                {benefitImageIcons && benefitImageIcons
                                    ?.filter((x: any) =>
                                        x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('flex')).slice(0, 4)
                                    .map((benefitIcon: BenefitIcons, index: number) => {
                                        return (
                                            // Replace this with the JSX you want to render for each filtered item
                                            <span className="benefit-icon">
                                                {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                    <img className="img-fluid" src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " title={benefitIcon.benefit} />
                                                ) : (
                                                    <img className="img-fluid" src="" alt=" " />
                                                )}
                                            </span>
                                        );
                                    })}
                                {benefitImageIcons && benefitImageIcons
                                    ?.filter((x: any) =>
                                        x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('flex')).slice(4).length !== 0 ?
                                    <DropdownButton id="dropdown-basic-button" className="benefit-count" title={benefitImageIcons && benefitImageIcons
                                        ?.filter((x: any) =>
                                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('flex')).slice(4).length + "+"}>
                                        {benefitImageIcons && benefitImageIcons
                                            ?.filter((x: any) =>
                                                x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('flex')).slice(4)
                                            .map((benefitIcon: BenefitIcons, index: number) => {
                                                return (
                                                    <>
                                                        {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                            <Dropdown.ItemText><img className="img-fluid " src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " /><span title={benefitIcon.benefit} className="text-truncate">{benefitIcon.benefit}</span></Dropdown.ItemText>
                                                        ) : (
                                                            <img className="img-fluid" src="" alt=" " />
                                                        )}
                                                    </>
                                                );
                                            })}
                                    </DropdownButton>
                                    : <></>}
                            </div>
                        </td>
                        <td className="direct-benefits" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>
                            <div className="benefits">
                                {benefitImageIcons && benefitImageIcons
                                    ?.filter((x: any) =>
                                        x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('direct')).slice(0, 4)
                                    .map((benefitIcon: BenefitIcons, index: number) => {
                                        return (
                                            // Replace this with the JSX you want to render for each filtered item
                                            <span className="benefit-icon">
                                                {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                    <img className="img-fluid" src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " title={benefitIcon.benefit} />
                                                ) : (
                                                    <img className="img-fluid" src="" alt=" " />
                                                )}
                                            </span>
                                        );
                                    })}
                                {benefitImageIcons && benefitImageIcons
                                    ?.filter((x: any) =>
                                        x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('direct')).slice(4).length !== 0 ?
                                    <DropdownButton id="dropdown-basic-button" className="benefit-count" title={benefitImageIcons && benefitImageIcons
                                        ?.filter((x: any) =>
                                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('direct')).slice(4).length + "+"}>
                                        {benefitImageIcons && benefitImageIcons
                                            ?.filter((x: any) =>
                                                x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase().includes('direct')).slice(4)
                                            .map((benefitIcon: BenefitIcons, index: number) => {
                                                return (
                                                    <>
                                                        {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                            <Dropdown.ItemText><img className="img-fluid " src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " /><span title={benefitIcon.benefit} className="text-truncate">{benefitIcon.benefit}</span></Dropdown.ItemText>
                                                        ) : (
                                                            <img className="img-fluid" src="" alt=" " />
                                                        )}
                                                    </>
                                                );
                                            })}
                                    </DropdownButton>
                                    : <></>}
                            </div>
                        </td>
                        {/*<td className="updated"  onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span className="text-truncate">{insuranceHealthPlan.modifiedInfo}</span></td>*/}
                        <td className="status-section" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span className={`status ${siteStatus?.colourStyles}`}>{siteStatus?.label}</span><span>{insuranceHealthPlan?.isPlanOverride ? <img className="img-fluid" style={{ marginLeft: '20px' }} src={OverrideIcon} /> : ''}</span></td>
                        <td className="status-section" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span className={`status ${benefitStatus?.colourStyles}`}>{benefitStatus?.label}</span></td>
                        {isAction && < td className="action" style={{ width: "99px" }} onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>{(isEmulator === 1 || isEmulator === 0) && <img className="img-fluid " src={isEmulator === 1 ? ViewPrimary : Info} alt=" " onClick={(e) => {
                            showPopUp(insuranceHealthPlan, isEmulator); e.stopPropagation();
                        }} />}</td>}

                    </tr>
                </tbody>
            </Table>
        </div>
        );
    });

    const filterbyStatus = (filterstatus: string[]) => {
        if (isNoSearchResult === false && isFilterApplied === true) {

            const filteredPlans = insuranceHealthPlans?.filter(x => search?.length > 2 ? x?.healthPlanName?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) : x?.healthPlanName)
                .filter(x => {
                    if (filterstatus.length === 0) {
                        return true;
                    }
                    return (filterstatus?.includes(x.benefitStatus) || filterstatus?.includes(x.siteStatus));
                });
            if (filteredPlans?.length === 0) {
                return (
                    <NotFound message={"No plans found"} colSpan={6} />
                );
            }

            else {
                var renderedInsuranceHealthPlans: any[] = (insuranceHealthPlans && insuranceHealthPlans
                    .filter(x => search?.length > 2 ? x?.healthPlanName?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) : x?.healthPlanName)
                    .filter(x => {
                        if (filterstatus.length === 0) {
                            return true;
                        }
                        return (filterstatus?.includes(x.benefitStatus) || filterstatus?.includes(x.siteStatus));
                    })) ?? [];
                return (
                    <AutoSizer>
                        {({ height, width }: { height: number, width: number }) => (
                            <List
                                itemCount={renderedInsuranceHealthPlans?.length}
                                height={height}
                                width={width}
                                itemSize={50}
                            >

                                {({ index, style }) => (
                                    <FilteredMemoizedRow
                                        key={index}
                                        style={style}
                                        insuranceHealthPlan={renderedInsuranceHealthPlans && renderedInsuranceHealthPlans[index]}
                                        isMobileMode={isMobileMode}
                                        benefitImageIcons={benefitImageIcons}
                                        handleHealthPlanClick={handleHealthPlanClick}
                                        isAction={isAction}
                                        colourMapping={colourMapping}
                                    />
                                )}
                            </List>
                        )}
                    </AutoSizer>
                )
            }
        }

        else {
            return (
                <NotFound message={"No plans found"} colSpan={6} />
            );
        }
    };

    const Applyfilters = () => {
        setInsuranceHealthPlans(totalHealthplans);
        setIsFilterApplied(true);
        setSelectedStatus(allselectedCheckBoxStatus);
        setFilteredStatus(allselectedCheckBoxStatus);
        setToggleFitlerVisibility(false);
        SetActiveInActive(false, false, false);
        filterbyStatus(allselectedCheckBoxStatus);
    };

    const handleClearAll = (active: boolean) => {
        setIsFilterApplied(false);
        setSelectedCheckBoxStatus([]);
        setAllselectedCheckBoxStatus([]);
        setSelectedStatus([]);
        setFilteredStatus([]);
        setToggleFitlerVisibility(false);
        if (active && search?.length <= 2) { ActiveOrInActivePlans(ActiveInactiveList.active); }
        else if (active && search && search?.length > 2) {
            ActiveOrInActivePlans(ActiveInactiveList.all);
        }
    };

    const SetActiveInActive = (active: boolean, inactive: boolean, all: boolean) => {
        setIsActive(active);
        setIsInActive(inactive);
        setIsAll(all);
    };

    const ActiveOrInActivePlans = (isactive: string) => {
        handleClearAll(false);

        let effFromDate = formatDate(selectedYear.benefitYear === year + 1 ? new Date(selectedYear.benefitYear, 0, 1) : new Date());
        let effToDate = formatDate(new Date(selectedYear.benefitYear, 11, 31));


        // setIsFilterApplied(true);
        switch (isactive) {
            case ActiveInactiveList.inactive:
                setInsuranceHealthPlans(totalHealthplans?.filter(x => x.isActivePlan === false
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === selectedYear.benefitYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                    && ((formatDate(new Date(carrierEffectiveFrom)) <= effFromDate || new Date(carrierEffectiveFrom).getFullYear() === selectedYear.benefitYear) && formatDate(new Date(carrierEffectiveTo)) >= effToDate)
                ));
                SetActiveInActive(false, true, false);
                break;
            case ActiveInactiveList.active:
                setInsuranceHealthPlans(totalHealthplans?.filter(x => x.isActivePlan === true
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === selectedYear.benefitYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                    && ((formatDate(new Date(carrierEffectiveFrom)) <= effFromDate || new Date(carrierEffectiveFrom).getFullYear() === selectedYear.benefitYear) && formatDate(new Date(carrierEffectiveTo)) >= effToDate)
                ));
                SetActiveInActive(true, false, false);
                break;
            default:
                setInsuranceHealthPlans(totalHealthplans?.filter(x => new Date(x?.effectiveFromDate).getFullYear() <= selectedYear.benefitYear
                    && new Date(x?.effectiveToDate).getFullYear() >= selectedYear.benefitYear
                    && new Date(carrierEffectiveFrom).getFullYear() <= selectedYear.benefitYear
                ));
                //setInsuranceHealthPlans(totalHealthplans);
                SetActiveInActive(false, false, true);
                break;
        }
    }

    const handletoggleFitlerVisibility = () => {
        setToggleFitlerVisibility(!toggleFitlerVisibility);
    };

    const handleClickevents = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation(); // Prevent the click event from propagating to the Dropdown.Item
    };

    const handleStatusChange = (option: string) => {
        let allSelectedCheckBoxValues = [...allselectedCheckBoxStatus];
        if (!selectedCheckBoxStatus?.includes(option)) {
            if (option?.toLowerCase() === statusList.pendingPublish?.toLowerCase()) {
                allSelectedCheckBoxValues.push('Failed');
            }
            setSelectedCheckBoxStatus([...selectedCheckBoxStatus, option]);
            allSelectedCheckBoxValues.push(option);
            setAllselectedCheckBoxStatus(allSelectedCheckBoxValues);
        }
        else {
            if (option?.toLowerCase() === statusList.pendingPublish?.toLowerCase()) {
                allSelectedCheckBoxValues = allSelectedCheckBoxValues.filter(item => item !== 'Failed')
            }
            setSelectedCheckBoxStatus(selectedCheckBoxStatus.filter(item => item !== option));
            allSelectedCheckBoxValues = allSelectedCheckBoxValues.filter(item => item !== option);
            setAllselectedCheckBoxStatus(allSelectedCheckBoxValues);
        }
    };



    const MemoizedRow = React.memo(({ key, style, insuranceHealthPlan, isMobileMode, benefitImageIcons, handleHealthPlanClick, isAction, colourMapping }: any) => {
        let isEmulator = insuranceHealthPlan.showicon;
        let benefitStatus = colourMapping.filter((e: any) => (e?.value?.toLowerCase() === insuranceHealthPlan.benefitStatus?.toLowerCase()))[0];
        let siteStatus = colourMapping.filter((e: any) => (e?.value?.toLowerCase() === insuranceHealthPlan.siteStatus?.toLowerCase()))[0];
        return (
            <div style={{ ...style }} key={key}>
                <Table className='h-100 w-100'>
                    <tbody style={{ border: "1px solid #F5F5F5", borderTop: 'none' }}>
                        <tr>
                            {!isMobileMode ? <td className="plans-info" style={{ width: "532px" }} onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>{insuranceHealthPlan.healthPlanName} </td> :
                                <td className="plans-info" style={{ width: "532px" }} onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>{insuranceHealthPlan.healthPlanName} </td>}
                            <td className="benefits-count" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span>{(benefitImageIcons && benefitImageIcons?.filter((x: any) =>
                                x.healthPlanName?.toLowerCase() === (insuranceHealthPlan.healthPlanName?.toLowerCase())).length)}</span></td>
                            <td className="benefit-type" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>
                                <div className="benefits">
                                    {benefitImageIcons && benefitImageIcons
                                        ?.filter((x: any) =>
                                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('flex')).slice(0, 4)
                                        .map((benefitIcon: BenefitIcons, index: number) => {
                                            return (
                                                // Replace this with the JSX you want to render for each filtered item
                                                <span className="benefit-icon">
                                                    {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                        <img className="img-fluid" src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " title={benefitIcon.benefit} />
                                                    ) : (
                                                        <img className="img-fluid" src="" alt=" " />
                                                    )}
                                                </span>
                                            );
                                        })}
                                    {benefitImageIcons && benefitImageIcons
                                        ?.filter((x: any) =>
                                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('flex')).slice(4).length !== 0 ?
                                        <DropdownButton id="dropdown-basic-button" className="benefit-count" title={benefitImageIcons && benefitImageIcons
                                            ?.filter((x: any) =>
                                                x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('flex')).slice(4).length + "+"}>
                                            {benefitImageIcons && benefitImageIcons
                                                ?.filter((x: any) =>
                                                    x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('flex')).slice(4)
                                                .map((benefitIcon: BenefitIcons, index: number) => {
                                                    return (
                                                        <>
                                                            {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                                <Dropdown.ItemText><img className="img-fluid " src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " /><span title={benefitIcon.benefit} className="text-truncate">{benefitIcon.benefit}</span></Dropdown.ItemText>
                                                            ) : (
                                                                <img className="img-fluid" src="" alt=" " />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </DropdownButton>
                                        : <></>}
                                </div>
                            </td>
                            <td className="direct-benefits" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}>
                                <div className="benefits">
                                    {benefitImageIcons && benefitImageIcons
                                        ?.filter((x: any) =>
                                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('direct')).slice(0, 4)
                                        .map((benefitIcon: BenefitIcons, index: number) => {
                                            return (
                                                // Replace this with the JSX you want to render for each filtered item
                                                <span className="benefit-icon">
                                                    {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                        <img className="img-fluid" src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " title={benefitIcon.benefit} />
                                                    ) : (
                                                        <img className="img-fluid" src="" alt=" " />
                                                    )}
                                                </span>
                                            );
                                        })}
                                    {benefitImageIcons && benefitImageIcons
                                        ?.filter((x: any) =>
                                            x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('direct')).slice(4).length !== 0 ?
                                        <DropdownButton id="dropdown-basic-button" className="benefit-count" title={benefitImageIcons && benefitImageIcons
                                            ?.filter((x: any) =>
                                                x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('direct')).slice(4).length + "+"}>
                                            {benefitImageIcons && benefitImageIcons
                                                ?.filter((x: any) =>
                                                    x.healthPlanName?.toLowerCase() === (insuranceHealthPlan?.healthPlanName?.toLowerCase()) && x.benefitType?.toLowerCase()?.includes('direct')).slice(4)
                                                .map((benefitIcon: BenefitIcons, index: number) => {
                                                    return (
                                                        <>
                                                            {(benefitIcon.imageNames !== undefined || benefitIcon.imageNames !== null) ? (
                                                                <Dropdown.ItemText><img className="img-fluid " src={GetImageCDNUrl + benefitIcon?.imageNames + ".svg"} alt=" " /><span title={benefitIcon.benefit} className="text-truncate">{benefitIcon.benefit}</span></Dropdown.ItemText>
                                                            ) : (
                                                                <img className="img-fluid" src="" alt=" " />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </DropdownButton>
                                        : <></>}
                                </div>
                            </td>
                            {/*<td className="updated"  onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span className="text-truncate">{insuranceHealthPlan.modifiedInfo}</span></td>*/}
                            <td className="status-section" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span className={`status ${siteStatus?.colourStyles}`}>{siteStatus?.label}</span><span>{insuranceHealthPlan?.isPlanOverride ? <img className="img-fluid" style={{ marginLeft: '20px' }} src={OverrideIcon} /> : ''}</span></td>
                            <td className="status-section" onClick={() => handleHealthPlanClick(insuranceHealthPlan)}><span className={`status ${benefitStatus?.colourStyles}`}>{benefitStatus?.label}</span></td>
                            {isAction &&
                                <td className="action" style={{ width: "99px" }} onClick={(e) => {
                                    handleHealthPlanClick(insuranceHealthPlan)
                                }}>{(isEmulator === 1 || isEmulator === 0) &&
                                    <img className="img-fluid " src={isEmulator === 1 ? ViewPrimary : Info}
                                        alt=" "
                                        onClick={(e) => { showPopUp(insuranceHealthPlan, isEmulator); e.stopPropagation(); }} />}
                                </td>}
                        </tr>
                    </tbody>
                </Table>
            </div>);
    });


    // Renders the insurance healthplans
    const renderInsuranceHealthPlans = () => {

        const renderedInsuranceHealthPlans = insuranceHealthPlans && insuranceHealthPlans
            .filter((x: any) => search?.length > 2 ? x?.healthPlanName?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) : x?.healthPlanName);
        if (!renderedInsuranceHealthPlans || renderedInsuranceHealthPlans.length === 0 || isNoSearchResult) {
            const message = isNoSearchResult
                ? 'No plans found'
                : isActive
                    ? 'No plans found'
                    : 'No plans found';
            return (
                <NotFound message={message} colSpan={6} />
            );
        } else {
            // Render the list when there are plans available
            return (
                <AutoSizer>
                    {({ height, width }: { height: number, width: number }) => (
                        <List
                            itemCount={renderedInsuranceHealthPlans.length}
                            height={height}
                            width={width}
                            itemSize={50}
                        >
                            {({ index, style }) => (
                                <MemoizedRow
                                    key={index}
                                    style={style}
                                    insuranceHealthPlan={renderedInsuranceHealthPlans[index]}
                                    isMobileMode={isMobileMode}
                                    benefitImageIcons={benefitImageIcons}
                                    handleHealthPlanClick={handleHealthPlanClick}
                                    isAction={isAction}
                                    colourMapping={colourMapping}
                                />
                            )}
                        </List>
                    )}
                </AutoSizer>
            );
        }
    };

    // Function to be executed when the Escape key is pressed
    const handleEscapeKey = () => {
        if (!showActivity.showPopUp) {
            setShowActivity(popup);
            setRedirectToken('');
            setSelectedOption('');
            setPreviewDate(moment(dateObject).format("MM-DD-YYYY"));
            setUniqueBenefitTypesArray([]);
            setListOfInformation([]);
        }
    };

    useEffect(() => {
        if (isFilterApplied) {
            setTotalPlansCount(getHealthPlanCount() ?? 0);
        } else {
            setTotalPlansCount(getHealthPlanCount() ?? 0);
        }
    }, [isFilterApplied, selectedStatus, insuranceHealthPlans]);

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        let selectedYear = GetKeyValueFromLocalStorage(SelectedYear) && JSON.parse(GetKeyValueFromLocalStorage(SelectedYear));
        let benefitYearsFromLocalStorage = GetKeyValueFromLocalStorage(BenefitYears) && JSON.parse(GetKeyValueFromLocalStorage(BenefitYears));
        setSelectedYear(selectedYear);
        setBenefitYears(benefitYearsFromLocalStorage);
    }, [])

    useEffect(() => {
        setLoader(true);
        fetchInsuranceHealthPlansOnCarrierId();
        benefitIcons();
    }, [localStorage.getItem(SelectedYear)])

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        if (!!selectedOption) {
            getMemberInformation(selectedOption);
        }
    }, [selectedOption])

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        if (!!memberInfo.nhMemberId && !!previewDate && memberInfo.success)
            getTokenInformation();
    }, [memberInfo.nhMemberId, previewDate])

    // Add a resize event listener to update the screen width state
    useEffect(() => {
        window.addEventListener("resize", updateScreenWidth);
        return () => window.removeEventListener("resize", updateScreenWidth);
    }, []);

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        const storedUserName = localStorage.getItem("userId");
        if (!!storedUserName && storedUserName?.toLowerCase() !== undefinedConst)
            setUserName(storedUserName);
    }, [])

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            // Check if the pressed key is the Escape key (case-insensitive comparison)
            if (event.key.toLowerCase() === 'escape') {
                handleEscapeKey();
            }
        };
        document.addEventListener("keydown", handleKeyPress);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);


    // Use useEffect to set the default active tab below 991px
    useEffect(() => {
        const handleResize = () => {
            const isBelow992 = window.innerWidth < 992;
            if (isBelow992) {
                setIsMobileMode(true);
            } else {
                setIsMobileMode(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        /* <div className={`tabs-container ${(loader ? "loading-skeleton" : "")}`}>*/
        <div className={`tabs-container ${(loader === true ? "loading-skeleton" : "")}`}>
            <ListGroup horizontal className="actions-icons d-lg-none" >
                <ListGroup.Item className="plans-search">
                    <img className="img-fluid" src={Search} alt=" " onClick={toggleSearchBox} />
                </ListGroup.Item>
            </ListGroup>
            <div className="table-actions">
                <div className="activity-buttons">
                    <Dropdown className="me-3 dropdown-arrow">
                        <Dropdown.Toggle variant="outline" id="dropdown-menu-align-start" className="rounded-pill m-0 btn-outline active">
                            {selectedYear?.benefitYear}
                            <img className="img-fluid arrow ms-1" src={DownArrowBlack} alt=" " />
                        </Dropdown.Toggle>
                        <Dropdown.Menu defaultValue={selectedYear?.benefitYear}>
                            {benefitYears && benefitYears.map((statusYear: any) => {
                                const status = statusYear?.status?.toLowerCase() === "inprogress"
                                    ? "review"
                                    : statusYear?.status?.toLowerCase();
                                return (
                                    <Dropdown.Item key={statusYear?.benefitYear} onClick={() => handleBenefitYearChange(statusYear)}>
                                        {statusYear?.benefitYear}
                                        <span className={`status ${status}`}>{statusYear?.status}</span>
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button className={isAll ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActivePlans(ActiveInactiveList.all)} >All</Button>
                    <Button className={isActive ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActivePlans(ActiveInactiveList.active)} >Active</Button>
                    <Button className={isInActive ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActivePlans(ActiveInactiveList.inactive)}>Non-Active</Button>
                    <div className="filter-section">
                        <Dropdown show={toggleFitlerVisibility}>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="filters" onClick={handletoggleFitlerVisibility}>
                                <img className="img-fluid me-2" src={Filter} alt=" " />
                                <span>Filters</span>

                                <div className={isActive || isAll || isInActive ? '' : 'filter-notification'}></div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="filter-dropdown">
                                <h5 className="mb-3">Filter by</h5>
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="filterStatus" className="form-field w-100">
                                        <FloatingLabel id="filterStatus" label="Status" className="form-field w-100">
                                            <input className="form-control"
                                                placeholder="Status"
                                                data-bs-toggle="dropdown"
                                                data-bs-display="static"
                                                aria-expanded="false"
                                                value={selectedfilterStatusText} />
                                            <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                        </FloatingLabel>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {statusOptions.map(option => {
                                            return <Dropdown.Item key={option} onClick={handleClickevents}>
                                                <div className="input-box">
                                                    <Form.Check aria-label="option 1"
                                                        className="form-check" id={option}
                                                        checked={selectedCheckBoxStatus?.includes(option)}
                                                        onChange={() => handleStatusChange(option)}
                                                        onClick={handleClickevents}
                                                    />
                                                    <label htmlFor={option}>{option}</label>
                                                </div>
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="filter-buttons">
                                    <Button className="btn-secondary btn-sm" onClick={Applyfilters}>Apply</Button>
                                    <Button className="btn-sm btn-outline-primary" onClick={() => handleClearAll(true)} >Clear All</Button>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <span className="filter-result">Showing {isFilterApplied ? getHealthPlanCount() : totalPlansCount} plans</span>
                </div>
                <div className="search-download">
                    <Button id="dropdown-menu-align-end" className="me-3 btn-outline-primary download d-none d-xl-flex"
                        data-toggle="tooltip"
                        title={`${props.activeInsuranceCarrier?.benefitYear && props.activeInsuranceCarrier?.benefitYear} Benefit Template`}
                        onContextMenu={(e) => { e.preventDefault() }} onClick={() => downloadExcelFile(1)}>
                        <img className="img-fluid" src={Download} alt=" " />
                    </Button>
                    {(screenWidth >= resolution || showSearchBox) && (
                        <div className="form-field table-search features-search">
                            <FloatingLabel controlId="floatingInput" label="Search Plans" className="m-0">
                                <input className="form-control search-input" type="search" placeholder="Search Plans" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static"
                                    aria-expanded="false"
                                    onChange={getSearchtext}
                                    value={search}
                                />
                                {search?.length === 0 ? <Image src={Search} className="form-field-icon" alt="" /> : <></>}
                            </FloatingLabel>
                        </div>
                    )}
                </div>
            </div>

            <Table className='h-100 w-100'>
                <thead>
                    <tr>
                        <th className="plans-info">
                            Plans
                        </th>
                        <th className="benefits-count">Benefits</th>
                        <th className="benefit-type">Flex Benefits</th>
                        <th className="direct-benefits">Direct Benefits</th>
                        <th className="status-section">Site Config Status</th>
                        <th className="status-section">Benefit Status</th>
                        {isAction && <th className="action">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {loader ? (
                        Array.from({ length: 15 }).map((_, index) => (
                            <tr key={index}>
                                <td className="plans-info"></td>
                                <td className="benefits-count"></td>
                                <td className="benefit-type"></td>
                                <td className="direct-benefits"></td>
                                <td className="status-section"></td>
                                <td className="status-section"></td>
                                {isAction && <td className="action"></td>}
                            </tr>
                        ))
                    ) : (
                        isFilterApplied ?
                            filterbyStatus(filteredStatus) : renderInsuranceHealthPlans()
                    )}

                </tbody>
            </Table>

            <Modal show={showActivityPopup} backdrop="static" keyboard={false} size="lg" centered>
                <div className={loading === true ? "loading" : ""}></div>
                <Modal.Header closeButton onClick={handleActivityCloseExcel}>
                    <Modal.Title>Steps for Hassle-Free {props.activeInsuranceCarrier?.benefitYear && props.activeInsuranceCarrier?.benefitYear} Excel File Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Before proceeding to download the excel file, please follow these steps for a hassle-free experience:</p>
                    <ListGroup as="ol" numbered className="noborder-list">
                        <ListGroup.Item as="li">Open Excel and go to the "File" tab in the ribbon.</ListGroup.Item>
                        <ListGroup.Item as="li">Click on "Options" to open the Excel Options dialog box.</ListGroup.Item>
                        <ListGroup.Item as="li">In the Excel Options dialog box, select "Trust Center" from the left-hand side menu.</ListGroup.Item>
                        <ListGroup.Item as="li">Click on the "Trust Center Settings" button on the right side of the dialog box.</ListGroup.Item>
                        <ListGroup.Item as="li">In the Trust Center dialog box, select "Trusted Locations" from the left-hand side menu.</ListGroup.Item>
                        <ListGroup.Item as="li">Click on the "Add new location" button.</ListGroup.Item>
                        <ListGroup.Item as="li">Click on the "Browse" button and Select "Downloads" Folder from the left-hand side menu and Click "Ok".</ListGroup.Item>
                        <ListGroup.Item as="li">Optionally, you can give a description for the trusted location in the "Description" field.</ListGroup.Item>
                        <ListGroup.Item as="li">Click on the "OK" button to save the trusted location.</ListGroup.Item>
                        <ListGroup.Item as="li">Click on "OK" again to close the Trust Center dialog box.</ListGroup.Item>
                        <ListGroup.Item as="li">Finally, click on "OK" in the Excel Options dialog box to apply the changes.</ListGroup.Item>
                    </ListGroup>
                    <p>Once you have added the downloads folder as a trusted location, Excel will consider it a safe location for opening files without security warnings or restrictions.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button variant="secondary" onClick={saveExcelFile}>Proceed to Download</Button>
                    <Button variant="primary" className="btn-outline-primary" onClick={handleActivityCloseExcel}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Launch Wizard Modal Popup */}
            <Modal
                className={`modal-large bottom ${((listOfMembers && listOfMembers.length < 3) || listOfMembers === undefined) ? 'Launchwizard' : ''}`}
                show={showActivity.showPopUp}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered
                scrollable
            >
                {isLoading && <div className="loading"></div>}
                <Modal.Header closeButton onClick={handleActivityClose}>
                    <Modal.Title>
                        {showActivity.isEmulator
                            ? <>
                                Emulator Launch Pad<br />
                                <h6><b>Plan -</b> {selectedHealthPlan}</h6>
                            </>
                            : "In Review"}
                    </Modal.Title>
                </Modal.Header>
                {showActivity.isEmulator ? <Modal.Body>
                    <div className="semect-member">
                        <Row>
                            <Col md={6}>
                                <h5>
                                    {`Select ${year} Member`} <sup className="required">*</sup>
                                </h5>
                                {(listOfMembers && listOfMembers.length > 0 && listOfMembers.filter((x: any) => x.benefitYear === year).length > 0 ? listOfMembers?.filter((x: any) => x.benefitYear === year).map((e: MemberInform, index: number) => {
                                    return (
                                        <div
                                            onClick={() => {

                                                handleRadioChange(e.nhMemberID)
                                                setSearchedMemberInfo('');
                                                setListOfExistingMembers([]);
                                                setExistingMembers(false);
                                                setIsValidMember(true);
                                                setIsMemberEntered(true);

                                            }} className={`section-outline ${selectedOption === e.nhMemberID ? 'active' : ''}`}>
                                            <Form.Check
                                                key={index}
                                                className="form-check"
                                                name="group1"
                                                type={"radio"}
                                                id={`reverse-radio-${index}`}
                                                value={e.nhMemberID}
                                                checked={selectedOption === e.nhMemberID}
                                            />
                                            <label>
                                                {e.firstName}{" "}{e.middleName}{" "}{e.lastName}
                                                <span className="d-block" title="">
                                                    {e.nhMemberID}{" "}{!!e.eligibilityIndData && e.eligibilityIndData}
                                                </span>
                                            </label>
                                        </div>
                                    );
                                })
                                    : <h6>No Members Found</h6>)}
                            </Col>
                            <Col md={6}>
                                <h5>
                                    {`Select ${year + 1} Member`} <sup className="required">*</sup>
                                </h5>
                                {(listOfMembers && listOfMembers.length > 0 && listOfMembers.filter((x:any)=>x.benefitYear === year+1).length > 0   ? listOfMembers?.filter((x: any) => x.benefitYear === year + 1).map((e: MemberInform, index: number) => {
                                    return (
                                        <div
                                            onClick={() => {

                                                handleRadioChange(e.nhMemberID)
                                                setSearchedMemberInfo('');
                                                setListOfExistingMembers([]);
                                                setExistingMembers(false);
                                                setIsValidMember(true);
                                                setIsMemberEntered(true);

                                            }} className={`section-outline ${selectedOption === e.nhMemberID ? 'active' : ''}`}>
                                            <Form.Check
                                                key={index}
                                                className="form-check"
                                                name="group1"
                                                type={"radio"}
                                                id={`reverse-radio-${index}`}
                                                value={e.nhMemberID}
                                                checked={selectedOption === e.nhMemberID}
                                            />
                                            <label>
                                                {e.firstName}{" "}{e.middleName}{" "}{e.lastName}
                                                <span className="d-block" title="">
                                                    {e.nhMemberID}{" "}{!!e.eligibilityIndData && e.eligibilityIndData}
                                                </span>
                                            </label>
                                        </div>
                                    );
                                })
                                    : <h6>No Members Found</h6>)}
                            </Col>
                        </Row>
                        <span className="error-message">{!memberInfo.success ? '*Failed to Fetch Member Details' : ''}</span>
                        <div className='border-list'>
                            <div onClick={() => {
                                setExistingMembers(true);
                                setSelectedOption('');
                                setMemberInfo(memberDetails);
                                setRedirectToken('');
                                setSearchedMemberInfo('');
                                setListOfExistingMembers([]);
                                setIsValidMember(true);
                                setIsMemberEntered(true);

                            }} className={`section-outline ${existingMembers === true ? 'active' : ''}`}>
                                <Form.Check
                                    key={1}
                                    className="form-check"
                                    name="group1"
                                    type={"radio"}
                                    id={`reverse-radio-${10}`}
                                    value={existingMembers?.toString()}
                                    checked={existingMembers === true}

                                />
                                <label>
                                    Select Existing Members
                                    <span className="d-block" title="">

                                    </span>
                                </label>
                            </div>
                            {/*<span className="error-message">{!userName ? '*User Name Not Found Please Refresh the session or contact admin' : ''}</span>*/}



                            {existingMembers &&
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <div className="form-field table-search features-search w-100 mw-100">
                                            <FloatingLabel controlId="floatingInput" label={`Search By ${getEnvironment(props.activeInsuranceCarrier.environmentId)} MemberID or Insurance Member ID*`} className="m-0">
                                                <input className="form-control search-input w-100" placeholder="Search Plans" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static"
                                                    aria-expanded="false"
                                                    onChange={getSearchMemberInfo}
                                                    onKeyDown={handleKeyDown}
                                                    value={searchedMemberInfo}
                                                />
                                                {/* <Image src={Search} className="form-field-icon" alt="" /> */}
                                                {<Image src={Search} className="form-field-icon" alt=""
                                                    onClick={() => {
                                                        if (!!searchedMemberInfo)
                                                            onClickHandler();
                                                        else
                                                            setIsMemberEntered(false);
                                                    }} />}
                                            </FloatingLabel>
                                            {!isMemberEntered && <h6 className="error-message"> NH Member Id or Insurance Member Id cannot be empty</h6>}
                                            {!isValidMember && <h6 className="error-message">No Members Found , Please do accurate search</h6>}
                                            {listOfExistingMembers && listOfExistingMembers.length > 1 && <h6 className="error-message">Multiple matches found with the search criteria.</h6>}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <div>
                                {listOfExistingMembers && listOfExistingMembers.length > 0 && <h6 className="date mt-4">Member Details <sup>*</sup></h6>}
                                {listOfExistingMembers && listOfExistingMembers.length > 0 ?
                                    <>
                                        <p className="mb-0">Name :{" " + listOfExistingMembers[0]?.firstName + " " + listOfExistingMembers[0]?.lastName} </p>
                                        <p>{getEnvironment(props.activeInsuranceCarrier.environmentId)} Member ID :{" " + listOfExistingMembers[0]?.nhMemberId} </p>
                                    </>

                                    : <></>

                                }
                            </div>
                        </div>
                    </div>
                    <h6 className="date">Select Future Preview Date <sup>*</sup></h6>
                    <p>
                        Date at which you want the test user to be.{" "}
                        <br className="d-none d-lg-block" />
                        {`Must be after 12/31/${props.activeInsuranceCarrier.benefitYear - 1} and before 1/1/${props.activeInsuranceCarrier.benefitYear + 1}.`}
                    </p>
                    <Row>
                        <Col xs={12} lg={6}>
                            {

                            }
                            <FloatingLabel
                                controlId="floatingInput"
                                label={`${previewDate === '' ? "Select Future Preview Date" : ""}`}
                                className={`${previewDate === '' ? "required " : ""}  m-0`}

                            >
                                <DatePicker
                                    //  ref={datePickerRef}
                                    className='form-control w-100'
                                    selected={previewDate ? moment(previewDate, 'MM-DD-YYYY').toDate() : null}
                                    onChange={(date) => {
                                        if (date) {
                                            let dt = "";
                                            dt = moment(date).format("MM-DD-YYYY");
                                            if (!!memberInfo.nhMemberId) {
                                                setIsLoading(true);
                                            }

                                            setPreviewDate(dt);
                                            setShowDatePicker(false);
                                        }
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText=""
                                    dateFormat="MM-dd-yyyy"
                                    onInputClick={() => { setShowDatePicker(true); }}
                                    onBlur={() => { setShowDatePicker(false); }}
                                    open={showDatePicker}
                                    popperPlacement='top'


                                > </DatePicker>
                                <Image src={calendarIcon} className="form-field-icon" onClick={() => setShowDatePicker(!showDatePicker)} />

                            </FloatingLabel>


                        </Col>
                    </Row>
                    <div id="previewDateId" ></div>
                </Modal.Body> :
                    <Modal.Body>
                        <ListGroup className="noborder-list plans-list" as="ul">
                            {mappingData.map((data: any, index) => (
                                <ListGroup.Item key={index} as="li">
                                    <h5>{data.label}</h5>
                                    <ListGroup className="noborder-list inner-plans plans-list" as="ul">
                                        {uniqueBenefitTypesArray.map((benefitType: any, index) => {
                                            const filteredItems = listOfInformation && listOfInformation.filter(item => item.benefitType === benefitType);
                                            return (
                                                filteredItems && (!filteredItems[0]?.[data["condition"]] && !!filteredItems[0]?.[data["comments"]]) && <ListGroup.Item key={index} as="li">
                                                    <img className="img-fluid me-2" src={InReview} alt=" " /> <span>{benefitType + (filteredItems && "- " + filteredItems[0]?.[data["comments"]])}</span>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>

                    </Modal.Body>}
                {showActivity.isEmulator && <Modal.Footer className="justify-content-start">
                    <Button className="btn-secondary " disabled={!(!!redirectToken) ? true : !(!!subDomain) ? true : false}
                        href={screenWidth >= resolution ? `https://${subDomain}.${MyBenefitsProURL}/emulator?redirectToken=${redirectToken}` : `${BenefitsProURL}?RedirectToken=${redirectToken}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {"My Benefits Pro "}
                        <img className="img-fluid ms-2" src={ExternalWhite} alt=" " />
                    </Button>

                    <Button className="btn-outline-primary" disabled={!(!!redirectToken) ? true : !(!!userName) ? true : false}
                        href={`${getMEPUIEnvironmentUrl(props.activeInsuranceCarrier.environmentId)}/emulator?RedirectToken=${redirectToken}`}
                        rel="noopener noreferrer"
                        target="_blank">
                        Launch MEP{" "}
                        <img className="img-fluid ms-2" src={ExternalGrey} alt=" " />
                    </Button>
                    <Button className="btn-secondary " disabled={true}
                    >
                        {"My Benefits Pro Agent "}
                        <img className="img-fluid ms-2" src={ExternalWhite} alt=" " />
                  </Button>
                </Modal.Footer>}
            </Modal>
        </div>
    )
}